.last-exchanges-wrapper {
  margin-top: 8%;
  display: flex; /* Включаем Flexbox */
  flex-direction: column;
  justify-content: center; /* Центрируем содержимое по горизонтали */
  align-items: center; /* Центрируем содержимое по вертикали */
  height: 100%; /* Задаем высоту контейнера */
}

.last-exchanges {
    color: #000; /* Белый текст */
    border-radius: 10px; /* Скругленные углы */
    
    padding: 20px; /* Внутренние отступы */
    width: 276px; /* Заданная ширина */
    border: 2px solid rgba(202, 201, 204, 1); /* пример границы */
    border-radius: 20px; /* скругление углов границы */
  }
  
  .last-exchanges h3 {
    margin-bottom: 20px; /* Отступ заголовка */
    font-size: 22px;
  }

  .divider {
    border-bottom: 2px solid #887d7d;
    margin-bottom: 20px;
  }
  
  .exchange-item {
    margin-bottom: 20px; /* Отступ между элементами списка */
  }
  
  .exchange-amount {
    font-size: 1.2rem; /* Размер шрифта для сумм */
    font-weight: bold; /* Жирное начертание */
  }
  
  .received-amount {
    color: #4CAF50; /* Зеленый цвет для полученной суммы */
  }
  
  .exchange-date {
    font-size: 0.9rem; /* Размер шрифта для даты */
    margin-bottom: 10px; /* Отступ после даты */
    color: rgba(150, 148, 153, 1);
    margin-top: 20px;
    
  }

  .exchange-date::before {
    content: '↓'; /* Символ стрелки вниз */
    margin-right: 5px; /* Отступ слева от текста */
    font-size: 1.5rem; /* Размер шрифта для стрелки */
    color: rgba(150, 148, 153, 1); /* Цвет стрелки */
}
  
.currency {
    font-size: small;
    color: rgba(143, 119, 240, 1);
  }

hr {
    border: 0; /* Убираем стандартный бордер */
    height: 1px; /* Высота линии */
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.75), rgba(203, 31, 31, 0), rgba(255, 255, 255, 0.75)); /* Градиент для линии */
    margin-bottom: 20px; /* Отступ после линии */
  }

  
.subscribe-button {
    background-color: rgba(143, 119, 240, 1); /* Фиолетовый фон кнопки */
    color: #fff; /* Белый текст кнопки */
    border: none;
    border-radius: 24px; /* Скругление углов кнопки */
    padding: 10px 20px;
    font-size: 1rem; /* Размер шрифта кнопки */
    cursor: pointer; /* Курсор в виде указателя */
    margin-top: 13px; /* Отступ сверху для кнопки */
    border-bottom: 8px solid #000000;
    font-weight: 600;
    cursor: pointer; /* чтобы показать, что это кликабельный элемент */
    transition: background 0.3s ease; /* плавный переход фона */
    text-align: center;
    width: 300px;
  }
  
  .subscribe-button:hover {
    background-color: rgba(157, 251, 254, 1);
    color: #000;
    text-decoration: none;
}

.subscribe-button__img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.title-container, .title-call-to-action {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 24px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    margin-bottom: 20px;
    background-image: url('./../../../img/title.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

.title-call-to-action {
  width: 100%;
  max-width: 100%;
  background-image: url('./../../../img/title-purple.svg');

}
  
.title-text {
    font-size: 3rem;
    color: #53405d; 
    text-align: center; /* Выравнивание текста по центру */
    word-wrap: break-word; /* Перенос слов */
    overflow-wrap: break-word; /* Обеспечение переноса слов при переполнении */
    margin: 0; /* Удаление внешних отступов */
    padding: 0 10px; /* Внутренний отступ для отступа текста от краёв */
    max-width: 550px; /* Максимальная ширина текста, зависит от размера вашего SVG */
  }

.title-description {
    max-width: calc(80% + 8%); /* 10% от 80% равно 8% */
    margin-left: auto;
    margin-right: auto;
    text-align: center; /* Если вы хотите центрировать текст */
    /* Добавьте другие стили текста, которые вам нужны */
  }

.title-description h2 {
  font-size: 18px;
}


.bot-title {
  font-style: normal;
  font-weight: bolder;
  font-size: 3rem;
  text-align: center;
  color: #2E2A34;
}

.m-s {
  margin-top: 2rem;
}

.m-s span {
  color: rgba(143, 119, 240, 1);
}

@media (max-width: 1024px) {
  .title-description {
    font-size: 14px;
    line-height: 17.5px;
    text-align: center;
    font-weight: 600;
  }
  .title-text, .bot-title {
    font-size: 1.5rem;
  }
}
 
  
.grid-container {
    margin: 50px auto;
    display: grid;
    grid-gap: 1.3rem;
    grid-template-columns: repeat(3, 1fr);
  }
.grid-item {
  padding: 10px;
  border: 1px solid #969499;
}

.grid-item a {
  font-family: 'Geologica';
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: left;
  color: #969499;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
}

.grid-item a span {
  font-weight: 700;
  color: #6c6b6d;
  transition: background 0.3s, color 0.3s;
}

.grid-item a:hover,
.grid-item a:hover span {
  color: #000000;
  opacity: 1;
}

.wigth-10 {
  width: 10%;
}

.article-container {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.article-container h2 {
  font-weight: 300;
  font-size: 2rem;
  margin-top: 1rem;
  color: #58555D;
} 

.article-container h3 {
  font-weight: 700;
  font-size: 1.5rem;
  color: #969499;
  margin: 0
} 
.table-responsive {
  overflow-x: auto
}

.rules-wrapper table {
  width: 100%; /* Гарантирует, что таблица растягивается на всю доступную ширину */
  min-width: 768px; /* Устанавливает минимальную ширину таблицы, адаптируйте под ваш дизайн */

}

.material-title {
  font-size: 1.5rem;
  font-weight: 300;
  color: #58555D;
}

.material-container {
  width: 70vw;
  margin-bottom: 0;
}

.material-wrapper {
  max-width: 100%;
}
.material-inner {
  padding: 10px 10px;
  line-height: normal;
} 

.top-50 {
  margin-top: 4rem;
}

.bot-30 {
  margin-bottom: 1.5rem;
}

.media-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
}

.video-responsive {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: center;
  width: 100%;
}

.video-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.video-content p {
  font-family: 'Geologica', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #969499;
  width: auto;
  margin: 0;
}

@media (max-width: 768px) {
  .grid-container {
      grid-template-columns: repeat(2, 1fr);

  }
  .article-container h2 {
    font-size: 1.5rem;
  }
  .article-container h3 {
    font-size: 1.25rem
  }
  .material-container {
    width: 90vw;
  }
}

@media (max-width: 480px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  }
  
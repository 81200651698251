/* Общий фон для секции */
.welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-content: center;
  gap: 32px;
  width: 100%;
  height: auto;
  background: #EDEFF0;
}

/* Стиль для основных блоков с текстом */
.content-block-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 3rem auto;
  gap: 2rem;
  /* margin: 2rem 15rem 2rem 15rem; */
}
.m-o {
  margin: 0 auto;
}
.conten-img-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 90%;
}

.conten-img-wrapper img {
  width: 17%;
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 7.5rem;
  margin-right: 7.5rem;
}

.content-block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 0px;
  margin-bottom: 2rem;
  white-space: pre-wrap;
}

/* Стиль для вертикальных линий (палок) */
.content-block::before {
  content: '';
  position: absolute;
  left: -1rem;
  top: 0;
  bottom: 0;
  width: 6px; /* Ширина линии, указанная в дизайне */
  background: #8F77F0; /* Цвет фиолетовой линии */

}

/* Стили для изменения цвета линий в разных блоках */
.content-block.priorities::before {
  background: #969499; /* Цвет серой линии */
}

.content-block.dimmarex::before {
  background: #8F77F0; /* Фиолетовый */
}

.content-block.call-to-action::before {
  background: #2E2A34; /* Темно-фиолетовый */
}


/* Стили для заголовков */
.content-block h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #8F77F0;
  margin: 0; /* Убираем стандартные отступы */
}

.content-block__number {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  padding: 10px;
  width: 32px;
  height: 32px;
  background: #8F77F0;
  border-radius: 30px;
  color: #FFFFFF;
  margin-bottom: 1rem;
}
.call-to-action h4 {
  color: #000;
}

.priorities h4 {
  color: #58555D;
}
.priorities .content-block__number {
  background: #58555D;
}
.call-to-action .content-block__number {
  background: #000;
}
/* Стили для абзацев */
.content-block p, .invoice-block p {
  font-family: 'Geologica', sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  color: #58555D;
  width: auto; /* или конкретная ширина, если это необходимо */
  margin: 0; /* Убираем стандартные отступы */
}

/* Стили для кнопок */
.telegram-button, .exchange-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 24px;
  width: 100%;
  min-width: 300px;
  height: auto;
  background: #9DFBFE; /* Цвет фона для .telegram-button */
  border: 2px solid #2E2A34;
  box-shadow: 0px 5px 0px #2E2A34;
  border-radius: 24px;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #2E2A34;
  cursor: pointer;
  text-decoration: none; /* Убрать подчеркивание для ссылок, если кнопка является ссылкой */
}

.m-w-40 {
  max-width: 40%;
}

.telegram-button:hover, .exchange-button:hover {
  background-color: #8F77F0;
  color: #000;
  text-decoration: none;
}
.exchange-button {
  background: #EDEFF0; /* Цвет фона для .exchange-button */
}

.block-title-h2, .block-title-h1, .block-title-h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  color: #2E2A34;
}

.block-title-h2 span, .block-title-h1 span, .block-title-h3 span {
  color: #8F77F0;
}

.block-title-h1 {
  font-size: 3rem;
}

.block-title-h3 {
  text-align: unset;
  font-size: 1.5rem;
}

@media (max-width: 1024px) {
  /* Уменьшаем внешние отступы и центрируем блоки */
  .content-block-container {
    width: 95%;
  }
  .m-w-40 {
    max-width: 100%;
  }
  .block-title-h2 {
    font-size: 1.5rem;
  }
  .block-title-h3 {
    font-size: 1.25rem;
  }
  .conten-img-wrapper {
    flex-direction: column; /* Стек элементов вертикально */
    align-items: center; /* Центрирование элементов */
    max-width: 100%;

  }

  /* Убираем фиксированные отступы у изображений */
  .conten-img-wrapper img {
    display: none;
  }

  .content-block {
    /* Позволяем блокам занимать всю доступную ширину */
    width: 100%; 
    margin-bottom: 1rem; /* Добавляем нижний отступ */
    padding: 0 1rem; /* Небольшой внутренний отступ */
  }

  /* Убираем псевдоэлементы (вертикальные линии) для мобильных устройств */
  .content-block::before {
    left: 0;
  }

  /* Центрируем заголовки */
  .content-block h2 {
    text-align: center;
  }

  /* Центрируем кнопки */

  /* Адаптируем размеры кнопок */
  .telegram-button, .exchange-button {
    width: 80%; /* Растягиваем на всю доступную ширину */
    height: auto; /* Высота по содержимому */
    padding: 16px; /* Поддерживаем отступы */
    margin: 0; /* Убираем внешние отступы */
    font-size: 16px;
  }
}


.advantages-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
  
.advantage-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    display: flex;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
  }
  
.advantage-card__icon-wrap {
    flex: 0 0 auto;
  }
  
.advantage-card__icon {
    width: 70px;
    height: auto;
  }
  
.advantage-card__text {
    flex: 1;
  }
  
.advantage-card__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #8F77F0;
  }

.advantage-card__desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
        color: #58555D;
  }


.all-included-item__icon {
  width: 64px;
  height: 60px;
  margin-bottom: 10px;
}


.all-included-item__title {
  font-size: 18px;
  width: 100%;
  font-weight: 600;
  color: #58555D;
}

.all-included-item__desc {
  font-size: 16px;
  width: 100%;
  line-height: 1.4;
  color: #8E8E8E;
}

.steps-block {
  width: 100%;
  margin: 0 auto; 
  padding: 60px 20px;
  box-sizing: border-box;
}

.steps-block__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 2rem;
  justify-content: center;
  max-width: calc(4 * 220px + 3 * 2rem);
  margin: 0 auto;
  width: 100%;
}

.steps-block__card {
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 20px;
  text-align: left;
  box-shadow: 0 0 10px rgba(0,0,0,0.03);
}

.steps-block__card-title {
  font-size: 18px;
  font-weight: 600;
  color: #58555D;
  margin-bottom: 8px;
}

.steps-block__card-desc {
  font-size: 16px;
  color: #8E8E8E;
  line-height: 1.4;
}

.packages-container, .packages-container__items {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
}
.packages-container__items {
  padding: 0;
}

.package-card {
  background-color: white;
  border-radius: 24px;
  border: 2px solid #e0e0e0;
  border-bottom: 6px solid;
  padding: 20px;
  width: 270px;
  height: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.package-card h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.package-detail {
  margin-bottom: 10px;
}

.package-detail p {
  margin: 0;
  font-size: 12px;
  line-height: 15px;
  color: #8E8E8E;
}

.package-card .percentage {
  font-weight: bold;
  font-size: 16px;
}

.package-card .amount {
  font-weight: bold;
  font-size: 16px;
}

.package-card .amount span {
  color: #2E2A34;
}


.note {
  font-size: 12px;
  color: #888;
  text-align: center;
}

.note-line {
  margin: 0 10px;
  position: relative;
}

.note-line::before,
.note-line::after {
  content: "";
  position: absolute;
  top: 7px;
  width: 30%;
  border-top: 1px solid #888;
}

.note-line::before {
  left: 0;
  transform: translateY(-50%);
  margin-right: 10px;
}

.note-line::after {
  right: 0;
  transform: translateY(-50%);
  margin-left: 10px;
}

.package-card.light {
  border-color: #969499;
}

.package-card.light h3 span {
  color: #969499;
}

.package-card.primary {
  border-color: #8F77F0;
}

.package-card.primary .amount, .package-card.primary .percentage {
  color: #8F77F0;
}

.package-card.vip {
  border-color: #f8d73e;
}

.package-card.vip .amount, .package-card.vip .percentage{
  color: #f8d73e;
}
.package-card.vip h3 span {
  color: #f8d73e;
}

.notes {
  width: max-content;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  background-color: #f8d73e;
}

.support-content-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.left-column,
.right-column {
  padding: 1rem;
}

.left-right-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.list {
  list-style: none;
  padding: 0;
}

.list-item {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  color: #4a4a4a;
  margin-bottom: 0.75rem;
}

.check-icon {
  color: #5f4dff;
  font-size: 1.25rem;
  margin-right: 0.5rem;
}

.support-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.support-item {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  color: #4a4a4a;
  white-space: nowrap;
}

.support-item strong {
  font-weight: bold;
  color: #4a4a4a;
  margin-right: 0.5rem;
}

.review-block {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
}

.review-block__images {
  position: relative;
  width: 584px;
  height: 248px;
  flex-shrink: 0;
}

.review-block__triangle {
  position: absolute;
  top: 0;
  left: 70px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.review-block__man {
  position: absolute;
  top: -10%;
  left: 47%;
  height: 110%;
  object-fit: contain;
}

.review-block__text {
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: center;
}


@media (max-width: 1024px) {
  .support_content-container {
    grid-template-columns: 1fr 1fr;
  }
  .packages-container {
    flex-direction: column;
    width: 100%;
  }
  .packages-container__items {
    flex-direction: column;
  }
  .package-card {
    width: 100%;
    height: auto;
  }
  .note-line::after,
  .note-line::before {
    width: 20%;
  }
}

@media (max-width: 768px) {
  .advantages-grid {
    grid-template-columns: 1fr;
  }
  .advantage-card__title {
    font-size: 20px;
  }
  .included-features-grid {
    grid-template-columns: 1fr;
  }
  .steps-block {
    padding: 40px 10px;
  }
  .steps-block__card {
    padding: 16px;
  }
  .note-line::after,
  .note-line::before {
    width: 5%;
  }
  .review-block__images {
    width: 200px;
    height: 248px;
  }
  .review-block__text {
    align-items: center;
  }
  .review-block__triangle{
    left: 5px;
  }
  .review-block__man {
    left: 0;
  }
}

@media screen and (max-width: 900px) {
    .advantages-grid {
      grid-template-columns: 1fr;
    }
    .advantage-card__title {
      font-size: 20px;
    }
    .included-features-grid {
      grid-template-columns: 1fr;
    }
    .steps-block {
      padding: 40px 10px;
    }
    .steps-block__card {
      padding: 16px;
    }
    .support-content-container {
      flex-direction: column;
    }
    .left-right-row {
      flex-direction: column;
    }
  }